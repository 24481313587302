import React, { useEffect, useState } from "react";

import "./ExpenseClaim.css";
import Form from "react-bootstrap/Form";

// Images //
import { icons } from "../../assets/index";

// Components //
import {
  PageHeading,
  DropDown,
  SearchBar,
  TableRowLoader,
  FormInput,
} from "../../common/components/index";
import Table from "react-bootstrap/Table";
import { dateFormatFullMonth, parseDate } from "../../constents";
import Pagination from "rc-pagination";
import { Button, Modal } from "react-bootstrap";

// API's //
import {
  changeExpenseStatusApi,
  downloadExpenseClaimApi,
  getExpenseClaimApi,
} from "../../api/expenseClaimApi";

// Hooks //
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

// Actions //
import {
  receivedError,
  requestSent,
  responseRecived,
} from "../../redux/actions/utilsActions";

const ExpenseClaim = () => {
  const TABS = {
    TAB_ONE: "Live Claims",
    TAB_TWO: "Settled Claims",
  };
  // Hooks //
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.utils);
  const { userToken } = useSelector((state) => state.user);

  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    show: 10,
  });

  const defaultFormData = {
    expense_claim_id: "",
    status: "",
    reason_for_rejection: '',
    date: ''
  }

  const [formData, setFormData] = useState(defaultFormData);
  const [tab, setTab] = useState(TABS.TAB_ONE);
  const [showRejectClaimModal, setShowRejectedClaimModal] = useState(false);
  const [currentPage, setCurrentPage] = useState();
  const [totalCount, setTotalCount] = useState(10);
  const [limitPerPage, setlimitPerPage] = useState(10);
  const [status, setStatus] = useState("PENDING");
  const [expenseClaimData, setExpenseClaimData] = useState([]);

  // API Call to get expense claims data //
  const getExpenseClaimData = async (value) => {
    try {
      dispatch(requestSent());
      const data = await getExpenseClaimApi(userToken, {
        page: value.page,
        limit: value.limit,
        status: value.status,
        date: value.date,
      });
      setExpenseClaimData(data.data.rows);
      setTotalCount(data.data.count);

      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
      dispatch(responseRecived());
    }
  };

  const approveRejectExpenseClaim = async (id, value) => {
    try {
      const { expense_claim_id, reason_for_rejection} = formData;
      dispatch(requestSent());
      await changeExpenseStatusApi(userToken, { expense_claim_id: id, reason_for_rejection, status: value });
      setFormData(defaultFormData)
      getExpenseClaimData({
        page: searchParams.get('page'),
        limit: limitPerPage,
        status: status,
        date: formData.date,
      });
      dispatch(responseRecived());
    } catch (error) {
      dispatch(receivedError(error));
    } finally {
      setShowRejectedClaimModal(false)
    }
  };

  const updatePageHandler = (p) => {
    setSearchParams({ page: p, show: searchParams.get("show") });
    setCurrentPage(p);
    getExpenseClaimData({
      page: p,
      limit: 8,
      status: status,
      date: formData.date,
    });
  };

  const handleShow = (value) => {
    setlimitPerPage(value);
    setSearchParams({ page: 1, show: value });
    getExpenseClaimData({
      page: 1,
      limit: value,
      status: status,
      date: formData.date,
    });
  };

  const handleDateChange = (value) => {
    setFormData(f => ({ ...f, date: value }))
    getExpenseClaimData({
      page: 1,
      limit: 8,
      status: status,
      date: value,
    });
  };

  const handleTabChange = () => {
    if (tab === TABS.TAB_ONE) {
      setTab(TABS.TAB_TWO)
      setStatus('APPROVED')
      getExpenseClaimData({
        page: searchParams.get("page"),
        limit: limitPerPage,
        status: "APPROVED",
        date: formData.date,
      });
    } else {
      setTab(TABS.TAB_ONE)
      setStatus('PENDING')
      getExpenseClaimData({
        page: searchParams.get("page"),
        limit: limitPerPage,
        status: "PENDING",
        date: formData.date,
      });
    }
  }

  const downloadExpenseClaimPdf = async (id) => {
    const data = await downloadExpenseClaimApi(userToken, id)
    const fileurl = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = fileurl;
    link.setAttribute('download', `expense-claim.pdf`);
    document.body.appendChild(link);
    link.click();
  }

  useEffect(() => {
    getExpenseClaimData({
      page: searchParams.get("page"),
      limit: limitPerPage,
      status: status,
      date: formData.date,
    });
    setCurrentPage(Number(searchParams.get("page")));
    setlimitPerPage(Number(searchParams.get("show")));
  }, []);

  // const RejectExpenseClaimsModal = () => {
  //   return (
  //     <Modal
  //       size="md"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //       show={showRejectClaimModal}
  //     >
  //       <Modal.Body>
  //         <div className="allert_img_cont">
  //           <h2>Please provide a reason why are you rejecting the claim ?</h2>
  //         </div>

  //         <FormInput
  //           lable=""
  //           placeholder={"Enter Resason for Rejection"}
  //           onchange={(value) => setFormData(f => ({ ...f, reason_for_rejection: value }))}
  //           value={formData.reason_for_rejection}
  //           type={"text"}
  //         />
  //       </Modal.Body>
  //       <Modal.Footer className="d-flex justify-content-center">
  //         <Button
  //           className="btn_style"
  //           variant="secondary"
  //           onClick={() => setShowRejectedClaimModal(false)}
  //         >
  //           Cancel
  //         </Button>
  //         <Button className="orange_btn btn_style" onClick={() => approveRejectExpenseClaim(formData.expense_claim_id, "REJECTED")}>
  //           Reject
  //         </Button>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };

  return (
    <>
      {/* Rejection Modal */}
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showRejectClaimModal}
      >
        <Modal.Body>
          <div className="allert_img_cont">
            <h2>Please provide a reason why are you rejecting the claim ?</h2>
          </div>

          <FormInput
            lable=""
            placeholder={"Enter Resason for Rejection"}
            onchange={(value) => setFormData(f => ({ ...f, reason_for_rejection: value }))}
            value={formData.reason_for_rejection}
            type={"text"}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button
            className="btn_style"
            variant="secondary"
            onClick={() => setShowRejectedClaimModal(false)}
          >
            Cancel
          </Button>
          <Button className="orange_btn btn_style" onClick={() => approveRejectExpenseClaim(formData.expense_claim_id, "REJECTED")}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <RejectExpenseClaimsModal /> */}
      <PageHeading
        title={"Expense Claim"}
        paragraph={
          "View your sub-contractors expense claim. These are to be paid direct to the sub-contractors by yourselves, and are separate to your payroll payments with subby"
        }
      />
      <div className="filter_cont justify-content-between">
        <div className="d-flex col-8 justify-content-between">
          <DropDown
            label={"Show"}
            listItems={["1", "2", "8", "10", "15", "20", "25"]}
            onSelect={handleShow}
            value={limitPerPage}
          />
          <SearchBar
            onChange={handleDateChange}
            value={formData.date}
            type={"date"}
            width={"50%"}
          />
        </div>
      </div>
      <div className="tabs_cont" style={{ margin: "10px" }}>
        <span
          className={`tab-label ${tab === TABS.TAB_ONE ? "active" : ""}`}
          onClick={handleTabChange}
        >
          Live Claims
        </span>
        <span
          className={`tab-label  ${tab === TABS.TAB_TWO ? "active" : ""}`}
          onClick={handleTabChange}
          style={{ marginLeft: "10px" }}
        >
          Settled Claims
        </span>
      </div>
      <div className="tab_cont">
        {tab === TABS.TAB_ONE ? (
          <div className="right-sec_cont" id="expense_claim">
            {loading ? (
              <TableRowLoader limit={8} />
            ) : (
              <>
                <Table className="common-table" bordered>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Claimed By</th>
                      <th>Date Claimed</th>
                      <th>Site/Project</th>
                      <th>Description of Claim</th>
                      <th>Amount Claimed</th>
                      <th>Receipt / Invoice</th>
                      <th>Authorise / Reject</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseClaimData?.map((item, index) =>

                      <tr
                        key={index}
                        style={{
                          display: "table-row",
                        }}
                      >
                        <td>
                          <img src={icons.expenseClaim} alt="" />
                        </td>
                        <td>
                          {`${item.SoleTrader?.SoleTraderProfile?.firstname}  
                        ${item?.SoleTrader?.SoleTraderProfile?.surname}`}
                        </td>
                        <td>
                          {dateFormatFullMonth(parseDate(item.amount_claimed_date))}
                        </td>

                        <td>{item.Site && item.Site.name}</td>
                        <td>{item.type}</td>

                        <td>
                          {item.amount_claimed
                            ? `${item.amount_claimed_currency} ${item.amount_claimed}`
                            : "Null"}
                        </td>
                        <td>
                          <button onClick={() => downloadExpenseClaimPdf(item.id)}>
                            Download
                          </button>
                        </td>
                        <td>
                          <div className="rounded-2 border">
                            <Form.Select
                              className="input"
                              onChange={(event) => {
                                const { value } = event.target;
                                setFormData({
                                  ...formData,
                                  status: value,
                                });

                                if (value === "APPROVED") {
                                  approveRejectExpenseClaim(item.id, value)
                                } else if (value === 'REJECTED') {
                                  setFormData(f => ({ ...f, expense_claim_id: item.id }))
                                  setShowRejectedClaimModal(true)
                                }
                              }}
                              value={undefined}
                            >
                              <option value=""></option>
                              <option value={"APPROVED"}>Authorised</option>
                              <option value={"REJECTED"}>Rejected</option>
                            </Form.Select>
                          </div>
                        </td>
                      </tr>

                    )}
                  </tbody>
                </Table>
                <div className="pagination_cont">
                  <Pagination
                    pageSize={limitPerPage}
                    onChange={updatePageHandler}
                    current={currentPage}
                    total={totalCount}
                  />
                </div>
              </>
            )}
          </div>
        ) : tab === TABS.TAB_TWO ? (
          <div className="right-sec_cont" id="expense_claim">
            {loading ? (
              <TableRowLoader limit={8} />
            ) : (
              <>
                <Table className="common-table" bordered>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Claimed By</th>
                      <th>Date Claimed</th>
                      <th>Site/Project</th>
                      <th>Description of Claim</th>
                      <th>Amount Claimed</th>
                      <th>Receipt / Invoice</th>
                      <th>Authorise / Reject</th>
                    </tr>
                  </thead>
                  <tbody>
                    {expenseClaimData?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <img src={icons.expenseClaim} alt="" />
                        </td>
                        <td>
                          {`${item.SoleTrader?.SoleTraderProfile?.firstname}  
                  ${item?.SoleTrader?.SoleTraderProfile?.surname}`}
                        </td>
                        <td>
                          {dateFormatFullMonth(parseDate(item.amount_claimed_date))}
                        </td>

                        <td>{item.Site && item.Site.name}</td>
                        <td>{item.type}</td>

                        <td>
                          {item.amount_claimed
                            ? `${item.amount_claimed_currency} ${item.amount_claimed}`
                            : "Null"}
                        </td>
                        <td>
                          <button onClick={() => downloadExpenseClaimPdf(item.id)}>
                            Download
                          </button>
                        </td>
                        <td
                          style={{
                            backgroundColor:
                              item?.status === "APPROVED"
                                ? "#92D050"
                                : item?.status === "REJECTED"
                                  ? "#FF0000"
                                  : "white",
                          }}
                        >
                          {item.status === "APPROVED"
                            ? "AUTHORISED"
                            : item.status === "REJECTED"
                              ? "REJECTED"
                              : ""}
                        </td>
                      </tr>
                    )
                    )}
                  </tbody>
                </Table>
                <div className="pagination_cont">
                  <Pagination
                    pageSize={limitPerPage}
                    onChange={updatePageHandler}
                    current={currentPage}
                    total={totalCount}
                  />
                </div>
              </>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ExpenseClaim;
